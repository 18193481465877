/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

type SkillCardProps = {
  link: string
  title: string
  children: React.ReactNode
  bg: string
  titlePos: 'top' | 'bottom'
}


const SkillCard = ({  title, titlePos = 'bottom', children, bg }: SkillCardProps) => {
    const titlePosStyle = {
        textTransform: `uppercase`,
        letterSpacing: `wide`,
        [titlePos === 'bottom' ? 'pt' : 'pb']: 4,
        fontSize: [4, 5],
        fontWeight: `medium`,
        lineHeight: 1,
    };

    const titleEl = (<div
                sx={titlePosStyle}
            >
                {title}
            </div>);

    return (
        <div
            sx={{
                width: `100%`,
                boxShadow: `lg`,
                position: `relative`,
                textDecoration: `none`,
                borderRadius: `lg`,
                px: 4,
                py: [4, 5],
                color: `white`,
                background: bg || `none`,
                transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
                "&:hover": {
                    color: `white !important`,
                    transform: `translateY(-5px)`,
                    boxShadow: `xl`,
                },
                "& a": {
                    color: '#00ee00',
                    'font-weight': 'bold',
                    'text-shadow': 'none',
                }
            }}
        >
            {titlePos === 'top' &&  titleEl }
            <div sx={{ opacity: 0.85, textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)` }}>{children}</div>
            {titlePos === 'bottom' && titleEl }
        </div>
    );
}

export default SkillCard
