import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ExternalLink from "../components/external-link";
import SkillCard from "../components/skill-card";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Skills`}</h2>
    <SkillCard title="Object Oriented Programming" bg="linear-gradient(to right, #2f855a 0%, #2b6cb0 100%)" mdxType="SkillCard">
  Olly has an excellent knowledge of <ExternalLink link="https://php.net" mdxType="ExternalLink">PHP</ExternalLink> and create clean, effective code built with modularity and extensibility in mind.
    </SkillCard>
    <SkillCard titlePos="top" title="PHP Frameworks" bg="linear-gradient(to right, #2b6cb0 0%, #2f855a 100%)" mdxType="SkillCard">
  Olly likes using <ExternalLink link="https://laravel.com/" mdxType="ExternalLink">Laravel</ExternalLink> to quickly scaffold customisable web applications and has a good working knowledge of MVC concepts to jump into other frameworks.
    </SkillCard>
    <SkillCard title="Javascript" bg="linear-gradient(to right, #2f855a 0%, #2b6cb0 100%)" mdxType="SkillCard">
  Olly likes using <ExternalLink link="https://vuejs.org/" mdxType="ExternalLink">Vue</ExternalLink> and <ExternalLink link="https://reactjs.org" mdxType="ExternalLink">React</ExternalLink> to create great user experiences, and can fall back on pure ES6 JS and jQuery where needed.
    </SkillCard>
    <SkillCard titlePos="top" title="DevOps" bg="linear-gradient(to right, #2b6cb0 0%, #2f855a 100%)" mdxType="SkillCard">
  Olly sets up CI Pipelines to integrate testing and deployment code seamlessly with <ExternalLink link="https://git-scm.com/" mdxType="ExternalLink">Git</ExternalLink>.
    </SkillCard>
    <SkillCard title="Docker" bg="linear-gradient(to right, #2f855a 0%, #2b6cb0 100%)" mdxType="SkillCard">
  Olly uses <ExternalLink link="https://www.docker.com/" mdxType="ExternalLink">Docker</ExternalLink> and <ExternalLink link="https://kubernetes.io/" mdxType="ExternalLink">Kubernetes</ExternalLink> to containerise applications for development and deployment.
    </SkillCard>
    <SkillCard titlePos="top" title="Content Management Systems" bg="linear-gradient(to right, #2b6cb0 0%, #2f855a 100%)" mdxType="SkillCard">
  Olly has extensive experience with CMSs including <ExternalLink link="https://wordpress.com/" mdxType="ExternalLink">Wordpress</ExternalLink> and <a href="https://www.drupal.org/">Drupal</a>.
    </SkillCard>
    <SkillCard titlePos="top" title="Server Admin" bg="linear-gradient(to right, #2f855a 0%, #2b6cb0 100%)" mdxType="SkillCard">
  Olly has the skills to set it up from scratch, optimise it for its given task and ensure it is up-to-date with the latest software.
    </SkillCard>
    <SkillCard title="Responsive Design" bg="linear-gradient(to right, #2b6cb0 0%, #2f855a 100%)" mdxType="SkillCard">
  Olly uses <ExternalLink link="https://www.tailwindapp.com/" mdxType="ExternalLink">Tailwind</ExternalLink> and <ExternalLink link="https://getbootstrap.com/" mdxType="ExternalLink">Bootstrap</ExternalLink> to bring your designs to life, on desktop and mobile.
    </SkillCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      