/** @jsx jsx */
import React from "react"
import { jsx, Link } from "theme-ui"

type ExternalLinkProps = {
  link: string
  children: React.ReactNode
}

const ExternalLink = ({ link,children }: ExternalLinkProps) => (
  <Link
    href={link}
    target="_blank"
    rel="noreferrer noopener"
  >{children}</Link>
)

export default ExternalLink
