/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { hidden } from "@lekoarts/gatsby-theme-cara/src/styles/utils"

const icons = {
  zero: {
    shape: (
        <path
        d="M 23.48,12.38
           C 23.48,12.38 23.48,16.64 23.48,16.64
             23.48,20.35 22.56,23.29 20.72,25.45
             19.27,27.15 17.44,28.00 15.23,28.00
             14.17,28.00 13.17,27.80 12.23,27.39
             11.30,26.98 10.50,26.42 9.84,25.70
             9.43,25.25 8.97,24.52 8.47,23.53
             7.98,22.53 7.63,21.61 7.42,20.77
             7.09,19.59 6.92,18.21 6.92,16.64
             6.92,16.64 6.92,12.38 6.92,12.38
             6.92,8.66 7.84,5.71 9.69,3.55
             11.13,1.85 12.96,1.00 15.17,1.00
             16.24,1.00 17.24,1.20 18.17,1.61
             19.11,2.02 19.91,2.58 20.56,3.30
             20.99,3.77 21.45,4.50 21.94,5.50
             22.43,6.50 22.79,7.41 23.02,8.23
             23.33,9.41 23.48,10.79 23.48,12.38 Z
           M 21.69,12.62
           C 21.69,10.98 21.45,9.45 20.97,8.05
             20.49,6.63 19.95,5.51 19.34,4.67
             18.99,4.20 18.52,3.82 17.94,3.56
             17.12,3.19 16.21,3.00 15.19,3.00
             13.16,2.82 11.58,3.71 10.44,5.67
             9.29,7.63 8.72,9.93 8.72,12.58
             8.72,12.58 8.72,16.41 8.72,16.41
             8.72,18.04 8.96,19.57 9.44,21.00
             9.91,22.42 10.46,23.50 11.08,24.25
             11.42,24.67 11.88,25.03 12.47,25.34
             13.28,25.78 14.20,26.00 15.23,26.00
             17.24,26.00 18.82,25.07 19.97,23.22
             21.11,21.36 21.69,19.10 21.69,16.45
             21.69,16.45 21.69,12.62 21.69,12.62 Z" />
    ),
    viewBox: `0 0 30 30`,
  },
  one: {
    shape: (
  <path d="M 16.09,1.00
           C 16.09,1.00 16.09,26.00 16.09,26.00
             16.09,26.00 22.28,26.00 22.28,26.00
             22.71,26.00 23.02,26.09 23.20,26.28
             23.39,26.46 23.48,26.70 23.48,27.00
             23.48,27.28 23.39,27.52 23.20,27.72
             23.02,27.91 22.71,28.00 22.28,28.00
             22.28,28.00 8.11,28.00 8.11,28.00
             7.68,28.00 7.38,27.91 7.19,27.72
             7.00,27.52 6.91,27.28 6.91,27.00
             6.91,26.70 7.00,26.46 7.19,26.28
             7.38,26.09 7.68,26.00 8.11,26.00
             8.11,26.00 14.30,26.00 14.30,26.00
             14.30,26.00 14.30,4.00 14.30,4.00
             14.30,4.00 8.41,4.94 8.41,4.94
             8.11,4.98 7.91,5.00 7.78,5.00
             7.56,5.00 7.36,4.92 7.19,4.77
             7.01,4.60 6.92,4.41 6.92,4.19
             6.92,4.00 6.99,3.82 7.14,3.66
             7.25,3.55 7.49,3.45 7.84,3.33
             7.84,3.33 16.09,1.00 16.09,1.00 Z" />

    ),
    viewBox: `0 0 30 30`,
  },
  triangle: {
    shape: (
      <polygon
        strokeWidth="1px"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="14.921,2.27 28.667,25.5 1.175,25.5 "
      />
    ),
    viewBox: `0 0 30 30`,
  },
  circle: {
    shape: (
      <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
    ),
    viewBox: `0 0 30 30`,
  },
  arrowUp: {
    shape: (
      <React.Fragment>
        <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />
        {` `}
        <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
      </React.Fragment>
    ),
    viewBox: `0 0 30 42`,
  },
  upDown: {
    shape: (
      <React.Fragment>
        <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
        <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
      </React.Fragment>
    ),
    viewBox: `0 0 30 44.58`,
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: `0 0 30 30`,
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
      />
    ),
    viewBox: `0 0 30 30`,
  },
  cross: {
    shape: (
      <path
        strokeWidth="3px"
        d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
      />
    ),
    viewBox: `0 0 100 100`,
  },
}

type IconType = "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross"

type SVGProps = {
  stroke?: boolean
  color?: string | number | any
  width: number
  icon: IconType
  left: string
  top: string
  hiddenMobile?: boolean
}

const SVG = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false }: SVGProps) => {
    const randomBinary = Math.random() < 0.5 ? 'zero' : 'one';
    return (
        <svg
            sx={{
                position: `absolute`,
                stroke: stroke ? `currentColor` : `none`,
                fill: `currentColor`,
                display: hiddenMobile ? hidden : `block`,
                color,
                width,
                left,
                top,
            }}
            viewBox={icons[randomBinary].viewBox}
        >
            {icons[randomBinary].shape}
        </svg>
    );
}

export default SVG
