import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Olly`}</h2>
    <p>{`Olly is a professional web developer with over 10 years experience.`}</p>
    <p>{`After working in an agency environment for 5 years he chose to try his hand at contracting and has been working with clients large and small ever since.`}</p>
    <p>{`Agency and contract work have provided Olly to work with a wide variety of web technologies both on the front- and back-end, of web applications, as well as the infrastructure required to deploy and serve them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      